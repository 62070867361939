import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import "./setor-de-estagios.css";
import tabelaDocumentos from "../components/images/tabelaDocumentos.png";
import banner_ciee from "../components/images/banner_ciee.gif";


const setordeestagios = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="setordeestagios-titulo">SETOR DE ESTÁGIOS</p>
    </Container>
    <Container>
      <Card style={{ boxShadow: "0 0 0 0", border: "0 none", outline: "0" }}>
        <Row>

                
          <p id="espaco"><center>
            <img className="banner_ciee" src={banner_ciee} alt=""></img>
            </center>
          </p>
          <p id="espaco">
            Dentro do{" "}
            <a
              id="cor"
              href="/portal"
            >
              Portal de Carreiras
            </a>
            {" "}da FATEC - São José dos Campos, o Setor de Estágios é responsável pelas seguintes formalizações:{" "}
          </p>
 
          
        <p id="subtitulo">Acordos de Estágio</p>
        <p id="espaco">
          O processo de contratação do aluno como estagiário necessita deste documento, onde são definidas as principais atividades, horário de trabalho, supervisor da empresa e professor avaliador na Faculdade.
        </p>
        
          <p id="subtitulo">Registros Curriculares</p>
          <p id="espaco">
          Todos os cursos da FATEC exigem ao menos 240 horas de estágio supervisionado. Esta exigência quando cumprida é incorporada ao histórico do aluno. Este processo é iniciado na Diretoria Acadêmica no momento da entrega do acordo de estágio e finalizada ao entregar o relatório final ao professor avaliador.
          <br />
          <mark id="marca">Para saber mais detalhes entre em contato com a Diretoria Acadêmica (Secretaria)</mark>. 
                </p>
          
          <p id="subtitulo">Alunos que trabalham na área</p>
        <p id="espaco">
        Trazer uma declaração de serviço (o exemplo de modelo está abaixo. Atenção, esse só é um exemplo, portanto é flexível para mudanças).
        <br />
        Apresentar uma fotocópia da carteira de trabalho (parte de identificação e contratação).
        <br />
        Ao final do curso o aluno deverá fazer um relatório final.
        <br />
        Somente com a entrega deste o estágio obrigatório, que neste caso está sendo substituído pelo trabalho na área, será finalizado.
        <br />
        O relatório final se refere a estágio, porém, essa denominação poderá ser substituída por funcionário, colaborador, atividades de serviço, etc.

        </p>

        <p id="subtitulo">Seguro obrigatório e horas de estágio</p>
        <p id="espaco">
        Atenção ao seguro obrigatório e às horas de estágio, o número de horas não pode ultrapassar 6h diárias e 30h semanais.
        <br />
        Não se esqueça de especificar o tempo de almoço.
        </p>

        <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
        <p id="subtitulo">Anexos e documentos para estágio</p>
        <p id="espaco">
        Abaixo a Lista de Documentos Obrigatórios para <font id="bold">Alunos</font> e <font id="bold">Empresas</font>:
        </p>
          <p className="monitoria-p">
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/anexo_1_lei_11_778.pdf"
            >
               Anexo 1 - Legislação Específica Sobre Estágio - LEI N.º 11.778
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/anexo_3_termo_de_compromisso_de_estagio.docx"
            >
              Anexo 3 - Termo de Compromisso de Estágio
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_ads.docx"
            >
              Modelo de Relatório de Estágio (Análise e Desenvolvimento de Sistemas)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_amd.docx"
            >
              Modelo de Relatório de Estágio (Automação e Manufatura Digital)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_bd.docx"
            >
              Modelo de Relatório de Estágio (Banco de Dados)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_dsm.docx"
            >
              Modelo de Relatório de Estágio (Desenvolvimento de Software Multiplataforma)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_gpi.docx"
            >
              Modelo de Relatório de Estágio (Gestão da Produção Industrial)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_logistica.docx"
            >
              Modelo de Relatório de Estágio (Logística)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_mav.docx"
            >
              Modelo de Relatório de Estágio (Manufatura Avançada)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_manutencao.docx"
            >
              Modelo de Relatório de Estágio (Manutenção de Aeronaves)
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_estagio_pea.docx"
            >
              Modelo de Relatório de Estágio (Projetos de Estruturas Aeronáuticas)
            </a>
			<br />
			<a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/estagio/modelo_relatorio_trabalho_equivalente_estagio.docx"
            >
              Modelo de Relatório de Trabalho Equivalente ao Estágio
            </a>
 
          </p>
        </Col>
      </Row>
    </Container>

        <p id="subtitulo">Empresas conveniadas para intermediar os estágios</p>
        <p id="espaco">
        Informação importante:
        <br />
        <br />
        Em atendimento à Portaria CEETEPS-GDS nº 3326, de 26 de julho de 2022, que revoga a Portaria nº 459/2008 de 30/12/2008, publicada no D.O.E. de 31/12/2008, Seção I, Pág. 63, a qual delegava competência, aos Diretores das Unidades de Ensino Técnico e Tecnológico do Centro Paula Souza, para a celebração de Convênios, e respectivos Termos de Compromisso, informamos que a formalização de estágio fica condicionada ao credenciamento da empresa contratante junto à empresa interveniente conveniadas.
        <br />
        <br />
	
        Segue relação das empresas conveniadas do Centro Paula Souza:<br /><br />
		
        001. Z-Estágios (Zuna Estágios)<br />
        002. Super Estágios<br />
        003. NUBE - Núcleo Brasileiro de Estágios Ltda.<br />
        004. Global Estágios<br />
        005. CIEE (de São Paulo)<br />
        006. Estagiar Serviços de Integração Empresa Escola (Estagiar)<br />
        007. Metta Integradora Empresa Escola (Metta)<br />
        008. Companhia de Estágios / PPM Human Resources (CIA de Estágios)<br />
        009. Futura Integrador Empresa Escola (Futura Estágios)<br />
        010. Agieer Consultoria de Talentos Ltda<br />
        011. MKM Mafii Ltda.<br />
        012. Instituto Euvaldo LODI<br />
        013. INTAL - Instituto Talentos<br />
        014. Conexão Estágios Ltda.<br />
        015. AEHDA - Associação de Educação do Homem de Amanhã - Araras<br />
        016. IUDS - Instituto Universal de Desenvolvimento Social.<br />
        017. Inter Estágios Eireli Me.<br />
        018. NAPE - Núcleo Assistencial para Estágio Ltda.<br />
        019. ESPRO - Associação de Ensino Social Profissionalizante.<br />
        020. G. L. D’Aolio Jornal e Estágios.<br />
        021. Instituto Via de Acesso Ruy.<br />
        022. Abre Estágios e Emprego – TL Serviços de Apoio Administrativo para Empresas Ltda.<br />
        023. IUDS Estagiando Ltda.<br />
        024. AGIEL - Agencia de Integração Empresa Escola Ltda.<br />
        025. Wall Jobs Tecnologias Ltda.<br />
        026. IAPE – Instituto de Apoio a Programas de Estágio.<br />
        027. Enterprise Solutions Ltda.<br />
        028. Estágio Sul RH.<br />
        029. SAEE - Soluções de Aprendizagem Empresa Escola Eireli.<br />
        030. Agiliza Serviços Empresarias Ltda.<br />
        031. AGIPE – Agência de Integração de Pessoas Ltda.<br />
        032. Empregar Já Estágios e Efetivos Ltda.<br />
        033. ABRH-RS Associação Brasileira de Recursos Humanos.<br />
        034. Belottis Consultoria e Treinamentos Ltda.<br />
        035. RH Unoprime - PR Serviços, Recrutamento e Seleção Ltda.<br />
        036. ABRE – São José dos Campos - Sampaio Trabalhos e Serviços Cadastrais Ltda.<br />
        037. Fibra RH – Equilibra Soluções Adm. e Treinamentos Gerenciais Ltda.<br />
        038. E Pro Soluções e Estágios Ltda.<br />
        039. Ativa Gestão de Estágios e Serviços Ltda.<br />
        040. Mega Estágios Ltda.<br />
        041. CIEE/RJ – Centro de Integração Empresa Escola do Rio de Janeiro.<br />
        042. Academia do Universitário Desenvolvimento Profissional Ltda.<br />
        043. Meso Carreira e Gestão Ltda.<br />
        044. PROEAJA - Projeto Educação de Adultos e Jovens de Araraquara.<br />
        045. Quali Serviços de Integração e Gerenciamento de Contratos Ltda.<br />
        046. CAEEP – Treinamentos e Estágios Ltda.<br />
        047. ITEMM – Instituto Técnico Educacional Mirian Menchini.<br />
        048. CIDE - Capacitação, Inserção e Desenvolvimento.<br />
        049. Ramires e Tavares Ltda.<br />
        050. Fundação Mirim Tupã – Núcleo de Atendimento ao Jovem Aprendiz.<br />
        051. Prospere Estágios e Recursos Humanos Ltda.<br />
        052. ISBET – Instituto Brasileiro Pro-Educação, Trabalho e Desenvolvimento.<br />
        053. Capacitare Recursos Humanos Eireli.<br />
        054. Stag Central de Estágios Ltda.<br />
        055. Pró-Jovem - Fábio Rodrigo do Nascimento EIRELI.<br />
        056. Avance Integradora de Estágios EIRELI.<br />
        057. Integrar Soluções em Estágios Ltda.<br />
        058. Evo Estágios VP Seleção e consultoria Ltda.<br />
        059. Evo Estágios Indaiatuba – R1 Serviços Administrativos e Treinamento de Pessoal EIRELI.<br />
        060. Érica Ramos de Moura Treinamentos e Consultoria ME. - Grupo Absolute.<br />
        061. Santo Amaro Ltda.<br />
        062. Kmi Centro de Integração Empresa Escola Ltda.<br />
        063. Evo Estágios Jaú - Daisa Cristina Siqueira Basso Corretora de Seguros (novo).<br />
        064. Evo Estágios Barueri - Cavalcanti Consultoria Ltda. (novo).<br />
        065. CAMPS - Centro de Aprendizagem e Mobilização Profissional e Social.<br />
        066. UPA – Universidade Patativa de Assaré.<br />
        067. Estagiários.com Web Service Ltda.<br />
        068. Inova Estágios Ltda.<br />
        069. Brilho Próprio Administração, Treinamento e Desenvolvimento Ltda.<br />
        070. CIEE/RS – Centro de Integração Empresa Escola do Rio Grande do Sul.<br />
        071. Estágio Total Ltda.<br />
		072. Oportuni Soluções em Recursos Humanos Ltda.<br />
		073. INV RH - Girardini Silva Gestão de Pessoas Eireli.<br />
		074. Alpha Estágio Ltda.<br />
		075. CPS - Centro de Promoção Social da Paróquia Nossa Sra. das Dores de Bariri<br />
		076. Gente Recursos Humanos - Simone Marcolino<br />
		077. Pyxo Estágios Agente de Integração Ltda.<br />
		078. Edukar Estágios, Comércio e Promoção em Vendas Ltda.<br />
		079. JCP Assessoria em Recursos Humanos Ltda.<br />
		080. Associação de Educação do Homem de Amanhã de Várzea Paulista<br />
		081. Dinardi Passini Consultoria em Recursos Humanos Ltda. - Evo Santos<br />
		082. Evo Estágios Ibitinga - Paulo Henrique Takakura<br />
		083. CA&T Conecta Agenciamento & Terceirização Ltda.<br />
		084. Universia Brasil S.A<br />
		085. Luppino Agência de Integração, Recrutamento e Seleção (Mais Estágios)<br />
		086. Vitah Apoio Administrativo Eireli<br />
		087. GNE - Gerenciamento Empresarial de Estágios Ltda.<br />
		088. IEGE - Instituto Brasileiro de Educação Profissional Ltda.<br />
		089. ASH Talentos Ltda.<br />
		090. G&G Recursos Humanos Ltda. – Prime Estágios<br />
		091. Jeanete G. Duarte<br />
		092. Adm Bplan Ltda. - RHF Talentos<br />
		093. Instituto de Pesquisas Econômicas – IPEFAE<br />
		094. Ser Especial - Associação Assistencial de Integração ao Trabalho<br />
		095. Bem Jobs - Willian Domingos Cardoso<br />
		096. MUDES - Fundação Movimento Universitário de Desenv. Econômico e Social<br />
		097. Philia Assessoria e Consultoria Empresarial Ltda. - Adonai RH<br />
		098. Estágios CIN-  Centro de Integração de Estudantes <br />
		099. Qualy Mão-de-Obra Temporária Ltda<br />
		100. F.A. Morelli Apoio à Educação Ltda<br />
		101. Guarda Mirim de Ituverava – GMI<br />
		102. Evo Estágios Bebedouro Ltda<br />
		103. Avantti Recrutamento e Seleção Ltda<br />
		104. Deal Solução Organizacional – RHF Estágios<br />
		105. Serviço de Orientação Multidisciplinar para Adolescentes de Americana – SOMA – Americana<br />
		106. Futuro Absoluto Treinamentos Ltda<br />
		107. Facilyt Assessoria Empresarial<br />
		108. Estagiare – Complexo Educacional GP Ltda<br />
		109. Mais Estágios Ltda<br />
		110. Portal do Estágio Ltda<br />
		111. Adonai RH Assessoria e Consultoria Ltda<br />
		112. Super Estágios Ltda<br />
		113. Instituto Cusma e Silva Ltda<br />
		114. RH Consultoria e Assessoria Empresarial Ltda<br />
		115. Mais Emprego<br />
		116. Motivar RH Eireli – ME<br />
		117. Auge Recursos Humanos e Serviços Empresariais LTDA<br />
		118. IAE – Instituto Coroados de Aprendizagem e Estágio<br />
		119. Chckt Estágios RH LTDA<br />
		</p>

        </Row>
      </Card>
    </Container>
    <Footer />
  </App>
);

export default setordeestagios;
